.Keyboard {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 0.375rem;
}

.Keyboard .KeyboardRow {
	display: flex;
	gap: 0.375rem;
}

.Keyboard .KeyboardRow .Key {
	width: 48px;
	height: 48px;
	font-size: 14px;
	background-color: #868686;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	-webkit-touch-callout: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	text-transform: uppercase;
	font-weight: 800;
	border-radius: 6px;
}

.mobile .Keyboard .KeyboardRow .Key {
	font-size: 12px;
	width: 22px;
}

.Keyboard .KeyboardRow .Backspace,
.Keyboard .KeyboardRow .Enter {
	width: auto !important;
	padding: 0px 18px;
}

.mobile .Keyboard .KeyboardRow .Backspace,
.mobile .Keyboard .KeyboardRow .Enter {
	padding: 0px 6px;
}
