.GuessInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  bottom: 0;
  background-color: rgb(33, 33, 33);
  padding-bottom: 20px;
}

.GuessInput .Keyboard {
  margin-top: 1rem;
}
