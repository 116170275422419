.Sharkle {
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  position: relative;
}

.Sharkle .guessGrid {
  flex-wrap: wrap;
}
